<template>
  <validation-observer ref="simpleRules">
    <interview-subject-form />
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import InterviewSubjectForm from '@/views/Admin/Defines/Interview_subjects/InterviewSubjectForm.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'Add',
  components: {
    InterviewSubjectForm,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['interviewSubjects/dataItem']
    },
    saveData() {
      return this.$store.getters['interviewSubjects/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('interviewSubjects/RESET_DATA_ITEM')
    localize('tr')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('interviewSubjects/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
